<template>
  <Row class="page5">
    <Col style="width: 50%; padding: 0 1%">
      <div class="description">
        <span class="title"
          ><span class="title-text">{{ picsTitle }}</span></span
        >
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="classifyExplain">{{ classifyExplain }}</span>
          <span v-else style="text-align: center; font-size: 18px"
            >暂无简介......</span
          >
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col
      style="width: 50%; height: 94.5%; display: flex; flex-direction: column"
    >
      <div class="center-bottom">
        <span class="title"
          ><span class="title-text">{{ picsTitle }}</span></span
        >
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <Row class="bottom-radars">
          <Col span="6">
            <radar id="bottom_1_1" :data="data5" ref="chart1"></radar>
          </Col>
          <Col span="6">
            <radar id="bottom_1_2" :data="data6" ref="chart2"></radar>
          </Col>
          <Col span="6">
            <radar id="bottom_1_3" :data="data7" ref="chart3"></radar>
          </Col>
          <Col span="6">
            <radar id="bottom_1_4" :data="data8" ref="chart4"></radar>
          </Col>
        </Row>
        <Row class="bottom-bars">
          <Col span="12">
            <double-bar id="bottom_2_1" :data="data9" ref="chart5"></double-bar>
          </Col>
          <Col span="12">
            <double-bar
              id="bottom_2_2"
              :data="data10"
              ref="chart6"
            ></double-bar>
          </Col>
          <!--            <Col span="6">-->
          <!--              <double-bar-->
          <!--                  id="bottom_2_3"-->
          <!--                  :data="data11"-->
          <!--                  ref="chart7"-->
          <!--              ></double-bar>-->
          <!--            </Col>-->
          <!--            <Col span="6">-->
          <!--              <double-bar-->
          <!--                  id="bottom_2_4"-->
          <!--                  :data="data12"-->
          <!--                  ref="chart8"-->
          <!--              ></double-bar>-->
          <!--            </Col>-->
        </Row>
      </div>
      <div class="bottom-list">
        <div class="bottom-left">
          <span class="title"
            ><span class="title-text">{{ picsTitle }}</span></span
          >
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="true"></workPics>
        </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class="title"><span class="title-text">{{photoTitle}}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div
        v-for="(item, index) in classify"
        :key="index"
        @click="chooseNav(item, index)"
        :class="index == chooseIndex ? 'on_workname' : 'workname'"
      >
        {{ item.classifyName }}
      </div>
    </div>
  </Row>
</template>

<script>
import { getDataApi, totalDataApi,getHonorData } from "@/api/dataList";
// import {handleOldNewArrFn} from "@/lib/oldNewArr";

const chinaMap = () => import("./components/chinaMap");
const radar = () => import("./components/radar");
const doubleBar = () => import("./components/page3/doubleBar");
// const jobStudy =()=> import('./components/page5/jobStudy')
const workPics = () => import("./components/page2/workPictures");
const honorPics = () => import("./components/page2/HonoraryPics.vue");

export default {
  name: "page5",
  components: {
    chinaMap,
    // jobStudy,
    workPics, //项目图片
    honorPics, //荣誉图片
    doubleBar,
    radar,
  },
  data() {
    return {
      //tabNav子菜单
      classify: [],
      chooseIndex: 0,
      photoTitle:'湖湘公益汇',
      picsTitle: "", //右下角图片标题
      //地图展示项目数据
      resdata: [],
      //项目图片数组
      workPicsList: [],
      data5: {
        title: "活动场次",
        position: ["5%", "14%"],
        center: ["50%", "60%"],
        indicator: [
          { text: "长沙市" },
          { text: "株洲市" },
          { text: "湘潭市" },
          { text: "衡阳市" },
          { text: "常德市" },
          { text: "益阳市" },
          { text: "娄底市" },
          { text: "郴州市" },
        ],
        data: [
          {
            name: "活动场次数据",
            color: "#0DE4EE",
            value: [],
          },
          // {
          //   name: '使用时长',
          //   color: '#0D88F3',
          //   value: [60, 5, 0.30, -100, 1500, 232]
          // }
        ],
      },
      data6: {
        title: "服务人次",
        position: ["5%", "14%"],
        center: ["50%", "60%"],
        indicator: [
          { text: "长沙市" },
          { text: "株洲市" },
          { text: "湘潭市" },
          { text: "衡阳市" },
          { text: "常德市" },
          { text: "益阳市" },
          { text: "娄底市" },
          { text: "郴州市" },
        ],
        data: [
          // {
          //   name: '个人信息涉军',
          //   color: '#6514FF',
          //   value: [100, 8, 0.40, -80, 2000, 345, 12, 654]
          // },
          {
            name: "服务人次数据",
            color: "#B370FD",
            value: [],
          },
        ],
      },
      data7: {
        title: "志愿者人数",
        position: ["5%", "14%"],
        center: ["50%", "60%"],
        indicator: [
          { text: "长沙市" },
          { text: "株洲市" },
          { text: "湘潭市" },
          { text: "衡阳市" },
          { text: "常德市" },
          { text: "益阳市" },
          { text: "娄底市" },
          { text: "郴州市" },
        ],
        data: [
          // {
          //   name: '主动',
          //   color: '#0096FE',
          //   value: [100, 8, 0.40, -80, 2000, 345, 123, 21, 34, 54, 431, 876]
          // },
          {
            name: "志愿者人数数据",
            color: "#9EEAFF",
            value: [],
          },
        ],
      },
      data8: {
        title: "覆盖学校数量",
        position: ["5%", "14%"],
        center: ["50%", "60%"],
        indicator: [
          { text: "长沙市" },
          { text: "株洲市" },
          { text: "湘潭市" },
          { text: "衡阳市" },
          { text: "常德市" },
          { text: "益阳市" },
        ],
        data: [
          {
            name: "覆盖学校数量数据",
            color: "#FD9800",
            value: [],
          },
          // {
          //   name: 'QQ',
          //   color: '#FDC673',
          //   value: [60, 5, 0.30, -100, 1500, 232]
          // }
        ],
      },
      data9: {
        title: "投入款物（万元）",
        data: [
          {
            name: "投入款物数据",
            color: "#00CCFF",
            value: [],
          },
          // {
          //   name: '使用时长',
          //   color: '#142AFE',
          //   value: ['112', '212', '42', '232', '123', '67']
          // }
        ],
      },
      data10: {
        title: "社会组织参与数",
        data: [
          {
            name: "社会组织参与数数据",
            color: "#B370FD",
            value: [],
          },
          // {
          //   name: '个人信息涉军应用个数',
          //   color: '#6514FF',
          //   value: ['112', '212', '42', '232', '123', '67'],
          // },
        ],
      },
      resizeFn: null,
      classifyExplain: "",
      vedioShow: false,
    };
  },
  created() {
    // this.getData();
  },
  methods: {
    // 获取数据
    async getData() {
      const res = await totalDataApi(29);
      this.classify = res.data.classify;
      this.chooseNav(this.classify[0], 0);
    },
    //  点击子菜单选择
    async chooseNav(item, index) {
      const res = await getDataApi(item.classifyId);
      this.resdata = res.rows;
      this.chooseIndex = index;
      this.classifyExplain = item.classifyExplain; //简介
      this.picsTitle = item.classifyName; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = item.img;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading = false; //加载中
      // 荣誉图片或者荣誉视频部分
      const HonRes = await getHonorData(item.classifyId);
      if (HonRes.code == 200) {
        console.log(HonRes);
        if (HonRes.data.switchOption == 1) {
          //荣誉图片
          this.$refs.honor.shows = 1;
          this.photoTitle = "荣誉图片";
          this.$refs.honor.videoUrl = "";
          this.$refs.honor.perfectPicsListL = [];
          this.$refs.honor.honoraryPicsList = item.honorImage;
        } else if (HonRes.data.switchOption == 2) {
          //精彩视频
          this.$refs.honor.shows = 2;
          if (HonRes.data.videoUrl) {
            HonRes.data.videoUrl = JSON.parse(HonRes.data.videoUrl);
          }
          this.photoTitle = HonRes.data.videoUrl[0].title||'精彩视频';
          console.log(this.photoTitle, "title");
          this.$refs.honor.honoraryPicsList = [];
          this.$refs.honor.perfectPicsListL = [];
          this.$refs.honor.videoUrl = HonRes.data.videoUrl[0].url;
        } else if (HonRes.data.switchOption == 3) {
          //精彩图片
          this.$refs.honor.shows = 3;
          if (HonRes.data.photoUrl) {
            HonRes.data.photoUrl = JSON.parse(HonRes.data.photoUrl);
          }
          this.photoTitle = "湖湘公益汇";
          this.$refs.honor.videoUrl = "";
          this.$refs.honor.honoraryPicsList = [];
          this.$refs.honor.perfectPicsListL = HonRes.data.photoUrl;
        }
      } else {
        this.$refs.honor.honoraryPicsList = [];
        this.$refs.honor.videoUrl = "";
        this.$refs.honor.perfectPicsListL = [];
      }
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load = false; //加载中
      //选择导航栏 把index对应传给value 从而获取不同数据
      for (var i = 0; i < this.resdata.length; i++) {
        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue =
          Number(this.resdata[i].list[0].attent) +
          Number(this.resdata[i].list[0].money) +
          Number(this.resdata[i].list[0].schoolCount) +
          Number(this.resdata[i].list[0].serviceCount) +
          Number(this.resdata[i].list[0].soprtcount) +
          Number(this.resdata[i].list[0].volunteerCount);
        this.resdata[i].value = totalValue;
      }
      this.$refs.chinaMap.seriesData = this.resdata;
      this.$refs.chinaMap.setMap(); //刷新地图数据
      //每次点击导航选项将之前数据置空 再进行下面操作
      console.log(this.$refs.chart1, "this.$refs.chart1");
      this.$refs.chart1.data.data[0].value = [];
      this.$refs.chart2.data.data[0].value = [];
      this.$refs.chart3.data.data[0].value = [];
      this.$refs.chart4.data.data[0].value = [];
      this.$refs.chart5.data.data[0].value = [];
      this.$refs.chart6.data.data[0].value = [];

      //各城市的数据遍历获取
      this.resdata.forEach((it) => {
        this.$refs.chart1.data.data[0].value.push(it.list[0].soprtcount); //活动场次
        this.$refs.chart2.data.data[0].value.push(it.list[0].serviceCount); //服务人次
        this.$refs.chart3.data.data[0].value.push(it.list[0].volunteerCount); //志愿者人数
        this.$refs.chart4.data.data[0].value.push(it.list[0].schoolCount); //覆盖学校数量
        this.$refs.chart5.data.data[0].value.push(it.list[0].money); //投入款物
        this.$refs.chart6.data.data[0].value.push(it.list[0].attent); //社会组织参与数
      });
      //刷新子组件数据
      this.$refs.chart1.setChart();
      this.$refs.chart2.setChart();
      this.$refs.chart3.setChart();
      this.$refs.chart4.setChart();
      this.$refs.chart5.setChart();
      this.$refs.chart6.setChart();
    },
  },
  mounted() {
    this.getData();
    this.resizeFn = this.$debounce(() => {
      this.$refs.chinaMap.setMap();
      this.$refs.chart1.setChart();
      this.$refs.chart2.setChart();
      this.$refs.chart3.setChart();
      this.$refs.chart4.setChart();
      this.$refs.chart5.setChart();
      this.$refs.chart6.setChart();
    }, 500);
    window.addEventListener("resize", this.resizeFn);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeFn);
  },
};
</script>

<style lang="less" scoped>
.page5 {
  height: 98%;
  width: 100%;
  padding: 8px 20px 20px;
  background: #03044a;
  overflow: hidden;
  position: relative;

  .tabNav {
    cursor: pointer;
    width: auto;
    margin: 0 auto;
    width: 100%;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    // left: 30%;

    .workname {
      color: #fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 40px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right: 1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }

    .on_workname {
      color: #4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }

  .ivu-col {
    height: 100%;
    float: left;
  }

  .left,
  .center-bottom,
  .description,
  .bottom-left,
  .bottom-right {
    height: 95%;
    border: 1px solid #0d2451;
    position: relative;
    background: #151456;

    .left1 {
      border-bottom: 1px solid #0d2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6eddf1;
      border: 2px solid #6eddf1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102e;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }

  .bottom-left,
  .bottom-right {
    width: 50%;
    height: 100% !important;
  }

  .bottom-list {
    width: 100%;
    height: 51% !important;
    display: flex;
    box-sizing: border-box;
  }

  .left {
    height: 74% !important;
    background: none !important;
    border: none !important;
  }

  .description {
    background: none !important;
    height: 25.5% !important;
    box-sizing: border-box;
    //padding-bottom: 2%;
    margin-bottom: -2%;
  }

  .describeList {
    text-indent: 1.5%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 1.5% auto;
    align-items: center;
    color: rgb(167, 198, 235);
    line-height: 27px;
    font-size: 15px;
    overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;

    span {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .describeList::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .describeList::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #a1b3dc;
  }

  .describeList::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: rgb(8, 25, 79);
  }

  .center-bottom {
    height: 48%;
    margin-bottom: 2%;

    .bottom-radars {
      margin-top: 5px;
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }
}
</style>
